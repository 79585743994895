import React from 'react';
import { Link } from 'gatsby';
import coustomstyle from './notfound.module.scss';
const NotFound = () => (
  <>
    <div style={{ marginTop: '10%' }}>
      <img src="/images/sds.png" className={coustomstyle.image} alt=" "></img>
      <h1 className={coustomstyle.h1}>Page not Found!</h1>
      <p className={coustomstyle.p}>
        The page you are looking for could not be found.
      </p>
      <p>
        <Link className={coustomstyle.p1} to="/">
          BACK TO HOMEPAGE
        </Link>
      </p>
    </div>
  </>
);
export default NotFound;
